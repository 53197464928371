import { BODY, BODY_BOLD, BUTTON_CLASS } from "../constants";
import exclamation from "../assets/exclamation.svg"
import { handleGoManageBooking } from "./Utils";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../store/users";
import { getCurrentUser, restoreSession } from "../store/session";
import { getCustAndSub } from "./apiCalls";
import { useEffect, useState } from "react";

const AlertBanner = () => {

  // const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);
  const [customer, setCustomer] = useState({});
  const expiry = currentUser.premiumExpiryDate;
  const expiryDate = new Date(expiry);
  const formattedExpiryDate = expiry ? expiryDate.toLocaleDateString('en-US', {month: '2-digit', day: '2-digit', year: 'numeric'}) : 'your premium expiry date';

  // const handleRemovePaymentIssueFlag = () => {
  //   dispatch(updateUser({id: currentUser.id, payment_issue: false}));
  //   restoreSession();
  // }

    const fetchSubscription = async () => {
      const res = await getCustAndSub();
      if (res.cus) setCustomer(res.cus);
    }
  
    useEffect(() => {
      fetchSubscription();
    }, [])

  return (
    <div className="w-[calc(100%-20px)] h-[81px] min-w-[1100px] flex flex-row justify-between items-center border border-4 border-solid border-error-red rounded m-3 p-4">
      <div className="h-full min-w-[50px]">
        <img src={exclamation} className="text-error-red h-full" />
      </div>
      <div className="flex flex-col items-center justify-center min-w-[775px]">
        <p className={BODY_BOLD + "text-error-red"}>There is an issue with your payment method.</p>
        <p className={BODY + "text-text-primary"}>Update your payment information on Stripe to continue using premium features. They will ask for the email address used to set up your billing: <span className="font-semibold">{customer && customer.email ? customer.email : 'email not available'}</span></p>
        <p className={BODY + "text-text-primary"}>{`If you take no action, your account will switch to the free version on ${formattedExpiryDate}. You will keep the 10 newest contact cards by default.`}</p>
      </div>
      <div>
        <button onClick={handleGoManageBooking} className={BUTTON_CLASS + 'min-w-[275px]'}>Update payment information <i className="fa-solid fa-arrow-right text-white ml-2"></i></button>
      </div>
      {/* <div>
        <i onClick={handleRemovePaymentIssueFlag} className="fa-solid fa-times text-text-primary hover:cursor-pointer hover:text-brand-primary active:text-link-blue"></i>
      </div> */}
    </div>
  );

}

export default AlertBanner;