import { useDispatch, useSelector } from "react-redux";
import { deleteUser } from "../store/users";
import { useHistory } from "react-router-dom";
import { getLoading, setLoading } from "../store/loading";
import { checkErrors } from "./Utils";
import Spinner from "./Spinner";
import { useState } from "react";
import { getCurrentUser } from "../store/session";
import csrfFetch from "../store/csrf";
import { BUTTON_CLASS_DELETE, BUTTON_CLASS_WHITE } from "../constants";


const AccountDelete = ({setShowAccountDeleteModal}) => {

  const dispatch = useDispatch();
  const history = useHistory();
  const currentUser = useSelector(getCurrentUser);
  const loading = useSelector(getLoading);
  const [errors, setErrors] = useState([]);

  const handleDeleteAccount = async (e) => {
    e.preventDefault();
    dispatch(setLoading(1));
    let res;
    let data;

    if (currentUser.stripeId) {
      const res = await csrfFetch(`/api/cancel-subscription`, {
        method: 'POST',
        body: JSON.stringify({userId: currentUser.id})
      });
      data = await res.json();
    }

    if (!currentUser.stripe_id || res.ok) {
      let currentErrors =[];
      let resp = await dispatch(deleteUser(currentUser.id))
      .catch(async (resp) => {
        currentErrors = await checkErrors(resp, setErrors);
      });
      dispatch(setLoading(0));
      setShowAccountDeleteModal(false);
      if (currentErrors.length) {
        alert(errors[0]);
      } else {
        dispatch(setLoading(0));
        setShowAccountDeleteModal(false);
        history.push('/');
      }
    } else if (data.errors) {
      dispatch(setLoading(0));
      alert(data.errors[0]);
      setErrors(data.errors);
    } else {
      dispatch(setLoading(0));
      alert('There was an error deleting your account. Please try again or contact us for assistance.');
    }


  }

  const handleCancel = e => {
    e.preventDefault();
    setShowAccountDeleteModal(false);
  }

  return (

    <div className="flex flex-col justify-start bg-slate-200 p-5 h-full min-w-[500px] rounded border-2 border-brand-primary bg-white">
      <p className="text-3xl pr-20">Delete account?</p>
      <p>All cards and the information they contain will be lost.</p>
      <p>You will no longer be charged a monthly fee.</p>
      <div className="flex flex-row justify-end">
        <button className={BUTTON_CLASS_WHITE + 'm-3'} onClick={handleCancel}>Cancel</button>
        <button className={BUTTON_CLASS_DELETE + 'm-3 min-w-[150px]'} onClick={handleDeleteAccount}>
          {loading == 1 ? 
          <Spinner/> :
          'Delete Account'
          }
          </button>
      </div>
    </div>

  )

}

export default AccountDelete;