import { useState } from "react";
import linked_blue_icon from "../assets/linked_blue.svg";
import linked_dark_icon from "../assets/linked_dark.svg";
import linked_inverted_icon from "../assets/linked_inverted.svg";


const LinkedInIcon = ({onClick}) => {

  const [iconSource, setIconSource] = useState(linked_blue_icon);

  const handleMouseEnter = () => {
    setIconSource(linked_inverted_icon);
  };

  const handleMouseLeave = () => {
    setIconSource(linked_blue_icon);
  };

  const handleMouseDown = () => {
    setIconSource(linked_dark_icon);
  };

  const handleMouseUp = () => {
    setIconSource(linked_inverted_icon); 
  };

  return (
    <i 
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
    >
      <img src={iconSource} alt="linkedin icon"/>
    </i>
  );

}

export default LinkedInIcon;