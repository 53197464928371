import { useDispatch, useSelector } from "react-redux";
import { setLoading, getLoading } from "../store/loading";
import Spinner from "./Spinner";
import { getCurrentUser } from "../store/session";
import { updateUser } from "../store/users";
import { BODY, BODY_BOLD, BUTTON_CLASS, BUTTON_CLASS_WHITE, HEADER_3 } from "../constants";

const Maintain10Modal = ({ setShowMaintain10Modal, setShowLimitModal }) => {

  const dispatch = useDispatch();
  const loading = useSelector(getLoading);
  const currentUser = useSelector(getCurrentUser);

  const handleMaintain10 = async () => {
    dispatch(setLoading(1));
    await dispatch(updateUser({id: currentUser.id, free_trial: false, plan: 'free'}));
    dispatch(setLoading(0));
    setShowMaintain10Modal(false);
    setShowLimitModal(false);
  }

  return (
    <div className="flex flex-col items-start justify-center bg-white rounded border-2 border-brand-primary">
      <div className="flex flex-col items-start justify-center w-[500px] rounded p-4">
        <p className={HEADER_3}>Maintain 10 contacts?</p>
        <div className="flex flex-col items-start mt-3">
          <p className={BODY + 'mb-4'}>You will keep the 10 newest contact cards by default.</p>
          <p className={BODY}>You can reactive your premium plan any time to restore your deleted contact cards and enable unlimited contact cards!</p>
        </div>
        <div className="flex flex-row justify-end w-full mt-8">
          <button onClick={() => setShowMaintain10Modal(false)} className={BUTTON_CLASS_WHITE}>
            Cancel
          </button>
          <button onClick={handleMaintain10} className={BUTTON_CLASS + 'min-w-[150px] ml-8'}>
            {loading === 1 ? <Spinner/> : 'Maintain 10 contacts'}
          </button>
        </div>
      </div>
    </div>
  )

}

export default Maintain10Modal;