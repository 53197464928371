import { addContact, fetchContacts, getNewestNContacts, getUserContacts, updateContactNotFormData } from "../store/contacts";
import ContactColumn from "./ContactColumn";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { getCurrentUser } from "../store/session";
import { Redirect } from "react-router-dom";
import { fetchTiers, getUserTiers } from "../store/tiers";
import { DragDropContext } from "react-beautiful-dnd";
import ColumnAdd from "./ColumnAdd";
import ContactShow from "./ContactShow";
import { Modal } from "../context/Modal";
import LimitReached from "./LimitReached";
import AlertBanner from "./AlertBanner";


const KanbanBoard = ({filterText, contactId}) => {

  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);
  const contacts = useSelector(getUserContacts(currentUser.id));
  const tiers = useSelector(getUserTiers(currentUser.id));
  const orderedTiers = tiers.sort((a, b) => a.position - b.position);
  const numContacts = contacts.length;
  const newestNcontacts = useSelector(getNewestNContacts(10, currentUser.id));
  const targetContact = contactId ? contacts.find(contact => contact.id === parseInt(contactId)) : null;
  const [filteredContacts, setFilteredContacts] = useState([]);
  const visibleContacts = currentUser.plan === 'free'? newestNcontacts : contacts;
  const [openContactFromExtension, setOpenContactFromExtension] = useState(contactId ? true : false);
  const [showLimitModal, setShowLimitModal] = useState(currentUser.freeTrial && currentUser.plan == 'free');
  

  useEffect(() => {
    contacts.forEach(contact => {
      if ((contact.tierId == undefined || contact.tierId == null) && (contact.columnOrder != null || contact.columnOrder != undefined)) {
        const desiredTier = tiers.find(tier => tier.position === contact.columnOrder);
        if (desiredTier) dispatch(updateContactNotFormData({id: contact.id, tier_id: desiredTier.id}))
      }
    })
  }, [tiers])

  useEffect(() => {
    let visibleContacts = currentUser.plan === 'free'? newestNcontacts : contacts;
    if (visibleContacts.length && filterText.length) {
      let filteredCons = visibleContacts.filter(contact => contact.name.toLowerCase().includes(filterText.toLowerCase()) || contact.company.toLowerCase().includes(filterText.toLowerCase()) || contact.title.toLowerCase().includes(filterText.toLowerCase()));
      setFilteredContacts(filteredCons);
    }
  }, [filterText])

  const move = (sourceArray, destinationArray, droppableSource, droppableDestination, dTierPosition, dTier) => {
    const sourceClone = Array.from(sourceArray);
    const destClone = Array.from(destinationArray);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
    removed.column_order = dTierPosition;
    removed.columnOrder = dTierPosition;
    removed.tier_id = dTier.id;
    removed.tierId = dTier.id;
    destClone.splice(droppableDestination.index, 0, removed);
  
    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;
  
    return result;
  };

  const reorder = (list, startIndex, endestIdex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endestIdex, 0, removed);
  
    return result;
  };

  const getInverseIndex = (array, index) => {
    return array.length - index - 1;
  }
  

  const onDragEnd = (result) => {
    if (result.source.droppableId && result.source.droppableId[0] === 't') {
      console.log('column was dragged')
      return
    }

    const { source, destination } = result;
    // dropped outside the list
    if (!destination) {
      return;
    }

    const sourceId = +source.droppableId;
    const destId = +destination.droppableId;
    const sTier= tiers.find(tier => tier.id === sourceId);
    const dTier = tiers.find(tier => tier.id === destId);
    const dTierPosition = dTier.position;
    const sTierContacts = contacts.filter(contact => contact.tierId === sTier.id).sort((a, b) => (b.verticalOrder + b.id / 100) - (a.verticalOrder + a.id / 100));
    const dTierContacts = contacts.filter(contact => contact.tierId === dTier.id).sort((a, b) => (b.verticalOrder + b.id / 100) - (a.verticalOrder + a.id / 100));

    if (sourceId === destId) {
      // placed in original index
      if (source.index === destination.index) {
        return;
      }
      const items = reorder(sTierContacts, source.index, destination.index);
      items.forEach((contact, index) => {
        contact.verticalOrder = getInverseIndex(items, index);
        contact.vertical_order = contact.verticalOrder;
        dispatch(updateContactNotFormData(contact));
      });
    } else {
      const moveResult = move(sTierContacts, dTierContacts, source, destination, dTierPosition, dTier);
      let draggedContact = moveResult[destId].find(contact => contact.id === +result.draggableId);
      dispatch(addContact(draggedContact));
      moveResult[destId].forEach((contact, index) => {
        contact.verticalOrder = getInverseIndex(moveResult[destId], index);
        contact.vertical_order = contact.verticalOrder;
        dispatch(updateContactNotFormData(contact));
      });
      moveResult[sourceId].forEach((contact, index) => {
        contact.verticalOrder = getInverseIndex(moveResult[sourceId], index);
        contact.vertical_order = contact.verticalOrder;
        dispatch(updateContactNotFormData(contact));
      });
    }
  }


  useEffect(() => {
    if (!contacts.length) dispatch(fetchContacts());
    if (!tiers.length) dispatch(fetchTiers());
  }, []);

  if (!currentUser) {
    return (
      <Redirect to="/"/>
    )
  };

  return (
    <div className="min-h-full flex flex-col">
      {(currentUser.paymentIssue && currentUser.plan == 'premium') && <AlertBanner />}
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="border-solid border-4 border-brand-primary rounded flex flex-row w-[calc(100%-20px)] justify-start min-h-full m-3 mt-0">
          <div className="board flex flex-row w-full justify-start overflow-x-auto mb-3 ml-3">
            {orderedTiers.map( tier => (
              <ContactColumn numContacts={numContacts} key={tier.id} tier={tier} contacts={filterText ? filteredContacts : visibleContacts}/>
            ))}
            <ColumnAdd />
          </div>
        </div>
        {(openContactFromExtension && targetContact) && <Modal children={<ContactShow contact={targetContact} setShowContactShow={setOpenContactFromExtension} order={targetContact.columnOrder}/>} />}
        {showLimitModal && <Modal children={<LimitReached trialExpired={true} setShowLimitModal={setShowLimitModal} />} />}
      </DragDropContext>
    </div>
  )

}

export default KanbanBoard;