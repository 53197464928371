import shot1 from '../assets/landing/lshot1.png';
import shot2 from '../assets/landing/contact_board_shot.jpg';
import contactShot1 from '../assets/landing/contact_shot_1.png';
import contactShot2 from '../assets/landing/contact_shot_2.png';
import contactShot3 from '../assets/landing/contact_shot_3.png';
import { useHistory } from 'react-router-dom';
import LandingHeader from './LandingHeader';
import Footer from './Footer';
import { BIG_BODY, BODY, BODY_BOLD, BUTTON_CLASS, HEADER_1, HEADER_2, HEADER_3, SUBTITLE, SUBTITLE_BOLD } from '../constants';
import MobileLandingHeader from './MobileLandingHeader';
import launch from '../assets/landing/launch.svg';
import vacancy from '../assets/landing/vacancy.svg';
import lady from '../assets/landing/lady.svg';
import cabinet from '../assets/landing/cabinet.svg';



const LandingPage2 = () => {

  const history = useHistory();

  const handleGoSignup = (e) => {
    history.push('/signup');
  }

  const mobileClient = window.innerWidth < 1024


  return (

    <div className="flex flex-col items-center justify-center px-4 lg:px-[100px]">
      { mobileClient ? <MobileLandingHeader/> : <LandingHeader/> }
      <div className='flex flex-col items-center w-full lg:w-[1144px]'>

        <div className='flex flex-col lg:flex-row items-center justify-between w-full my-8 lg:my-[64px] lg:mb-[128px]'>
          <div className="flex flex-col items-start justify-center">
            <p className={ mobileClient ? HEADER_2 + 'mb-4' : HEADER_1 + 'mb-4'}>Build connections.</p>
            <p className={mobileClient ? HEADER_2 + 'mb-4' : HEADER_1 + 'mb-4'}>Launch your career.</p>
            <p className={SUBTITLE + 'mb-4 leading-tight lg:w-[448px]'}>Build professional relationships that support your career growth.</p>
            <p className='mb-8'>Manage your network with ease and watch your career take off.</p>
            <div className='w-full flex flex-row lg:justify-start justify-center'>
              <button onClick={handleGoSignup} className={BUTTON_CLASS}>Start networking with ease today</button>
            </div>
          </div>
          <img src={launch} className='lg:h-[467px] w-auto my-12 lg:my-0' />
        </div>
        <div className='w-full aspect-video'>
          <iframe  width="100%" height="100%" src="https://www.youtube.com/embed/xik7RRf2FEM?si=VcUXDuzuyvVPOTP-" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
        </div>

        <div className='flex flex-col items-center justify-center w-full mt-12 lg:mt-24'>
          <p className={mobileClient ? HEADER_3 + 'mb-10 w-full italic text-center' : HEADER_2 + ' mb-8 italic w-full text-center'}>How did you find your current job?</p>
          <div className='flex flex-col lg:flex-row justify-center items-center mb-12'>
            <div className='bg-bp10 w-[363px] rounded-xl drop-shadow-xl lg:mr-6 p-4 mb-6'>
              <p className={SUBTITLE + 'leading-tight'}>"I didn't find my job through any of the job boards, I found it through networking."</p>
            </div>
            <div className='bg-bp10 w-[363px] rounded-xl drop-shadow-xl lg:mr-6 p-4 mb-6'>
              <p className={SUBTITLE + 'leading-tight'}>“I got my job through a networking connection.”</p>
            </div>
            <div className='bg-bp10 w-[363px] rounded-xl drop-shadow-xl p-4 mb-6'>
              <p className={SUBTITLE + 'leading-tight'}>“I focused on building relationships networking more than on applications.”</p>
            </div>
          </div>
        </div>
        <div className='flex flex-col-reverse lg:flex-row items-center justify-between w-full lg:mb-[128px] mb-12'>
          <img src={vacancy} className='lg:h-[467px] w-auto my-8'/>
          <div className='flex flex-col items-center justify-center'>
            <div className='flex flex-row justify-start items-center lg:w-[486px] mb-12'>
              <p className={HEADER_1 + 'text-brand-primary mr-2'}>88%</p>
              <p className={SUBTITLE + 'lg:w-[425px] leading-tight'}>of employers rate referrals as the best ROI for sourcing talent.</p>
            </div>
            <div className='flex flex-row justify-start items-center lg:w-[486px]'>
              <p className={HEADER_1 + 'text-brand-primary mr-2'}>54%</p>
              <p className={SUBTITLE + 'lg:w-[425px] leading-tight'}>of hires originated from referrals in 2022.</p>
            </div>
          </div>
        </div>
        <p className={mobileClient ? HEADER_3 + 'mb-8 w-full text-center' : HEADER_2 + 'mb-8 w-full text-center'}>Your network is your net worth.</p>
        <div className='flex flex-col lg:flex-row items-center justify-between w-full mb-8 lg:mb-16'>
          <div className='flex flex-col lg:w-[450px]'>
            <p className={mobileClient ? SUBTITLE_BOLD + 'mb-4' : HEADER_3 + 'mb-4'}>Managing your network is a pain.</p>
            <p className={mobileClient ? BIG_BODY + 'mb-4' : SUBTITLE + 'mb-4'}>Tired of copying and pasting contacts into spreadsheets or Notion tables?</p>
            <p className={mobileClient ? BIG_BODY + 'mb-4' : SUBTITLE + 'mb-4'}>It’s frustrating. </p>
            <p className={mobileClient ? BIG_BODY + 'mb-4' : SUBTITLE + 'mb-4'}>It's time-consuming.</p>
            <p className={mobileClient ? BIG_BODY + 'mb-4' : SUBTITLE + 'mb-4'}>You miss opportunities because you lose track and forget to follow up.</p>
            <p className={mobileClient ? BIG_BODY + 'mb-4' : SUBTITLE + 'mb-4'}>Writing a follow-up message is difficult.</p>
          </div>
          <img src={lady} className='lg:h-[467px] w-auto my-8'/>
        </div>
        <div className='flex flex-col-reverse lg:flex-row items-center justify-between w-full lg:mb-[128px] mb-[64px]'>
          <img src={cabinet} className='lg:h-[467px] w-auto my-8'/>
          <div className='flex flex-col lg:w-[450px]'>
            <p className={mobileClient ? SUBTITLE_BOLD + 'mb-4' : HEADER_3 + 'mb-4'}>UNetwrk makes it easy.</p>
            <p className={mobileClient ? BIG_BODY + 'mb-4' : SUBTITLE + 'mb-4'}>Use our Chrome extension to save contacts from LinkedIn.</p>
            <p className={mobileClient ? BIG_BODY + 'mb-4' : SUBTITLE + 'mb-4'}>Your connections organized in one place.</p>
            <p className={mobileClient ? BIG_BODY + 'mb-4' : SUBTITLE + 'mb-4'}>Keep track of your communication.</p>
            <p className={mobileClient ? BIG_BODY + 'mb-4' : SUBTITLE + 'mb-4'}>Get reminders to follow up.</p>
            <p className={mobileClient ? BIG_BODY + 'mb-4' : SUBTITLE + 'mb-4'}>Generate a follow-up message with AI.</p>
          </div>
        </div>
        <p className={mobileClient ? HEADER_3 + 'w-full text-center' : HEADER_2 + 'mb-1 w-full text-center'}>How UNetwrk makes networking easy.</p>
        <p className={mobileClient ? SUBTITLE_BOLD + 'w-full text-center mt-5 mb-10' : HEADER_3 + 'mb-8 w-full text-center'}>Autosave contact’s info from LinkedIn. Organize your contacts.</p>
        <div className='flex flex-col-reverse lg:flex-row justify-between items-center lg:mb-20 mb-8 w-full lg:w-[1144px] '>
          <div className='flex flex-col lg:w-1/3 my-6' >
            {!mobileClient && <p className={mobileClient ? SUBTITLE_BOLD + 'text-center' : HEADER_3}>Chrome extension</p>}
            <p className={BODY + 'leading-tight mt-2'}>Use the Chrome extension to add contacts from LinkedIn. The information is automatically saved to your contact card board.</p>
          </div>
          <div className='flex items-center justify-center w-full aspect-[16/9] lg:w-[680px] lg:h-[400px] bg-gradient-to-b from-[#00B395] to-[#004D40] rounded-lg drop-shadow-lg'>
            <img className='w-[calc(100%-30px)] h-[calc(100%-30px)]' src={shot1} alt='shot1' />
          </div>
          {mobileClient && <p className={mobileClient ? SUBTITLE_BOLD + 'text-center mb-4' : HEADER_3}>Chrome extension</p>}
        </div>
        <div className='flex flex-col-reverse lg:flex-row flex-row justify-between items-center lg:mb-20 mb-8 w-full lg:w-[1144px]  '>
          <div className='flex flex-col lg:w-1/3 my-6' >
          {!mobileClient && <p className={mobileClient ? SUBTITLE_BOLD + 'text-center' : SUBTITLE_BOLD}>Contact card board</p>}
            <p className={BODY + 'leading-tight mt-2'}>Organize contact cards in columns. Use the preset column headers or edit them to your preferences. </p>
          </div>
          <div className='flex items-center justify-center w-full aspect-[16/9] lg:w-[680px] lg:h-[400px] bg-gradient-to-b from-[#00B395] to-[#004D40] rounded-lg drop-shadow-lg'>
            <img className='w-[calc(100%-30px)] h-[calc(100%-30px)]' src={shot2} alt='shot2' />
          </div>
          {mobileClient && <p className={mobileClient ? SUBTITLE_BOLD + 'text-center mb-4' : SUBTITLE_BOLD}>Contact card board</p>}
        </div>
        <div className='w-full'>
          <p className={mobileClient ? HEADER_3 + 'w-full text-center' : HEADER_2 + 'mb-1 w-full text-center'}>Contact cards</p>
          <p className={mobileClient ? SUBTITLE_BOLD + 'w-full text-center mt-2 mb-6' : SUBTITLE_BOLD + 'mb-4 w-full text-center'}>All your networking details in one place. Smarter follow-ups made easy.</p>
          <div className='flex flex-col lg:flex-row items-center justify-center'>
            <div className='flex flex-col items-center justify-center mb-6 lg:mr-12 lg:mb-0'>
              <p className={SUBTITLE}>Contact information</p>
              <div className='flex items-center justify-center w-[332px] h-[195px] bg-gradient-to-b from-[#00B395] to-[#004D40] rounded drop-shadow-lg my-3'>
                <img className='w-[312px]' src={contactShot1} alt='shot1' />
              </div>
              <p className={BODY + ' w-[332px]'}>All your contact’s key info in one spot, plus a link to their LinkedIn profile.</p>
            </div>
            <div className='flex flex-col items-center justify-center mb-6 lg:mr-12 lg:mb-0'>
              <p className={SUBTITLE}>Interaction notes</p>
              <div className='flex items-center justify-center w-[332px] h-[195px] bg-gradient-to-b from-[#00B395] to-[#004D40] rounded drop-shadow-lg my-3'>
                <img className='w-[312px]' src={contactShot2} alt='shot1' />
              </div>
              <p className={BODY + ' w-[332px]'}>Keep good interaction notes and set a reminder date to follow up.</p>
            </div>
            <div className='flex flex-col items-center justify-center'>
              <p className={SUBTITLE}>AI-generated follow-up</p>
              <div className='flex items-center justify-center w-[332px] h-[195px] bg-gradient-to-b from-[#00B395] to-[#004D40] rounded drop-shadow-lg my-3'>
                <img className='w-[312px]' src={contactShot3} alt='shot1' />
              </div>
              <p className={BODY + ' w-[332px]'}>Let AI draft a follow-up message. Email it or copy to send on LinkedIn.</p>
            </div>
          </div>
        </div>
        <div className='flex flex-col items-center justify-center pt-0 lg:w-[592px] text-center mt-16 lg:mt-[128px] lg:mb-16'>
          <p className={HEADER_2 + 'mb-4 w-full lg:w-[590px]'}>Network your way to success!</p>
          <p className={HEADER_3}>Make networking easier and more effective.</p>
          <div className='flex flex-col lg:flex-row justify-center items-center w-full mt-8'>
            <div className='flex flex-col bg-pale-green p-3 w-[216px] rounded lg:mr-12 mb-6 lg:mb-0'>
              <div className='flex flex-col items-center justify-center bg-white mb-3 rounded drop-shadow p-3'>
                <p className={SUBTITLE_BOLD}>Free</p>
                <p className={BODY_BOLD}>$0/month</p>
              </div>
              <div className='flex flex-col items-center justify-center bg-white rounded drop-shadow p-3'>
                <p><span className={BODY}>10</span> contact cards</p>
                <p>Chrome extension</p>
              </div>
            </div>
            <div className='flex flex-col bg-pale-green p-3 w-[216px] rounded'>
              <div className='flex flex-col items-center justify-center bg-white mb-3 rounded drop-shadow p-3'>
                <p className={SUBTITLE_BOLD}>Premium</p>
                <p className={BODY_BOLD}>$10/month</p>
              </div>
              <div className='flex flex-col items-center justify-center bg-white rounded drop-shadow p-3'>
                <p><span className={BODY_BOLD}>Unlimited</span> contact cards</p>
                <p>Chrome extension</p>
              </div>
            </div>
          </div>
          <button className={BUTTON_CLASS + 'mt-6 w-[268px] lg:w-[483px]'} onClick={handleGoSignup}>Start building your network today</button>
        </div>
        <div className='lg:w-2/3 my-12 lg:mt-12 lg: mb-24'>
          <iframe src="https://embeds.beehiiv.com/fcbdbeaf-b9a3-41c3-83b3-83d3b52a2a27" data-test-id="beehiiv-embed" width="100%" height="320" frameBorder="0" scrolling="no" style={{'borderRadius': '4px', 'border': '2px solid #e5e7eb', 'margin': '0', 'backgroundColor': 'white'}}></iframe>
        </div>
      </div>
      <Footer/>
    </div>

  )

}

export default LandingPage2;